// External
import Grid from '@taskrabbit/meadow-web/lib/Grid';
import Image from 'next/image';
import styled from '@taskrabbit/meadow-web/lib/Theme/styled';
import Typography from '@taskrabbit/meadow-web/lib/Typography';
import Link from 'next/link';

// Internal
import contentfulImageLoader from '~utils/contentfulImageLoader';
import {
  StyledContainer,
  StyledSection,
} from '~components/marketing/index.styled';
import RichText from './RichText';

// Types
import type { Satisfaction } from '~server/types/content';

const StyledImageWrapper = styled('div')(() => ({
  marginTop: '24px',
  minHeight: '48px',
  position: 'relative',
  width: '225px',
}));

const ProtectionSatisfactionProof = ({
  header1,
  header2,
  guarantee,
  imageLinkUrl,
  imageText,
  imageUrl,
}: Partial<Satisfaction>) => (
  <StyledContainer>
    <StyledSection>
      <Typography
        sx={({
          meadow: {
            purpose: { impact },
          },
        }) => ({
          alignSelf: 'start',
          color: impact.emphasis,
          mb: { md: 6, xs: 5 },
        })}
        variant="h2"
      >
        {header1}
        <Typography color="primary.main" component="span" variant="h2">
          &nbsp;{header2}
        </Typography>
      </Typography>

      <Grid container spacing={6} sx={{ pr: { sm: 0, xs: 8 } }}>
        {guarantee?.map(({ header, richText }, index) => (
          <Grid item key={header} md={4} sm={6} xs={12}>
            <Typography
              color="impact.main"
              component="h3"
              sx={{ mb: 2 }}
              variant="subtitle1"
            >
              {header}
            </Typography>

            <RichText richText={richText} />

            {/* Must check index such that image only appears on first entry */}
            {imageUrl && index === 0 && imageLinkUrl && (
              <StyledImageWrapper>
                <Link href={imageLinkUrl}>
                  <Image
                    alt={imageText ?? ''}
                    fill={true}
                    loader={contentfulImageLoader}
                    sizes="225px"
                    src={imageUrl}
                    style={{
                      cursor: 'pointer',
                      objectFit: 'contain',
                      objectPosition: 'center',
                    }}
                  />
                </Link>
              </StyledImageWrapper>
            )}
          </Grid>
        ))}
      </Grid>
    </StyledSection>
  </StyledContainer>
);

export default ProtectionSatisfactionProof;
