// External
import Grid from '@taskrabbit/meadow-web/lib/Grid';
import ButtonChoice from '@taskrabbit/meadow-web/lib/ButtonChoice';
import Container from '@taskrabbit/meadow-web/lib/Container';
import IcoMoon from '@taskrabbit/meadow-web/lib/IcoMoon';

// Internal
import {
  GET_HELP_TODAY_SECTION,
  PAGE_HOME,
  SERVICE_PAGE_LINK_CLICKED,
} from '~hooks/useSegment/eventNames';

// Types
import type { HomeIndexData } from '~server/trpc/router/page/home/types';
import { StyledHeader, StyledLink } from './index.styled';

// Hooks
import useSegment from '~hooks/useSegment';

const GetHelpToday = ({
  header,
  links,
  linkText,
  linkUrl,
}: HomeIndexData['getHelpToday']) => {
  const { trackEvent } = useSegment();

  const trackServiceSelect = (linkText: string) => {
    trackEvent({
      name: SERVICE_PAGE_LINK_CLICKED,
      properties: {
        link_label: linkText,
        page: PAGE_HOME,
        trigger_location: GET_HELP_TODAY_SECTION,
      },
    });
  };

  return (
    <Container
      data-testid="get-help-today-container"
      sx={{ py: { md: 11, xs: 6 } }}
    >
      <StyledHeader sx={{ mb: { md: 6, xs: 4 } }} variant="h2">
        {header}
      </StyledHeader>

      <Grid
        alignItems="center"
        container
        spacing={3}
        sx={{ mb: { md: 6, xs: 4 } }}
      >
        {links.map((link) => (
          <Grid item key={link.linkText}>
            <div onClick={() => trackServiceSelect(link.linkText)}>
              <ButtonChoice
                // TODO: Use relative links after migrating the service pages to tr_web/next
                href={link.linkUrl}
                sx={({ breakpoints }) => ({
                  [breakpoints.down('md')]: {
                    display: 'none',
                  },
                })}
              >
                {link.linkText}
              </ButtonChoice>

              <ButtonChoice
                // TODO: Use relative links after migrating the service pages to tr_web/next
                href={link.linkUrl}
                size="small"
                sx={({ breakpoints }) => ({
                  [breakpoints.up('md')]: {
                    display: 'none',
                  },
                })}
              >
                {link.linkText}
              </ButtonChoice>
            </div>
          </Grid>
        ))}
      </Grid>

      {/* TODO: Use relative links after migrating the service pages to tr_web/next */}
      <StyledLink
        href={linkUrl}
        onClick={() => trackServiceSelect(linkText)}
        target="_blank"
      >
        {linkText}
      </StyledLink>
      <IcoMoon name="lnr-chevron-right" sx={{ fontSize: 16, ml: 1 }} />
    </Container>
  );
};

export default GetHelpToday;
