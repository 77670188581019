// External
import StarRating from '@taskrabbit/meadow-web/lib/StarRating';
import Typography from '@taskrabbit/meadow-web/lib/Typography';
import styled from '@taskrabbit/meadow-web/lib/Theme/styled';

// Internal
import { StyledNextLink, TruncatedText } from './index.styled';

// Types
import type { Review } from '~server/types/content';

const StyledReview = styled('li')(({ theme: { breakpoints } }) => ({
  flex: '0 0 auto',

  width: '240px',
  [breakpoints.up('md')]: {
    maxWidth: '320px',
    overflow: 'hidden',
    width: '320px',
  },
}));

export const ReviewItem = ({
  linkText,
  linkUrl,
  locale,
  name,
  rating,
  text,
}: Review & { locale: string }) => (
  <StyledReview>
    <Typography
      component="span"
      sx={{ color: 'impact.main', pr: 1.2 }}
      variant="subtitle3"
    >
      {name}
    </Typography>
    <StarRating value={rating} />
    <TruncatedText
      $clampValue={6}
      component="span"
      sx={{ color: 'impact.main', mb: 2, mt: 2 }}
      variant="body1"
    >
      {text}
    </TruncatedText>
    {linkText && linkUrl ? (
      <StyledNextLink href={linkUrl} locale={locale}>
        {linkText}
      </StyledNextLink>
    ) : null}
  </StyledReview>
);
