// Types
export enum paths {
  CONFIRM = '/confirm',
  DETAILS = '/details',
  RECOMMENDATIONS = '/recommendations',
  REPOST = '/book/repost',
  SCHEDULE = '/schedule',
  MATCH = '/match',
  OPTIONS = '/options',
  OPTIONS_SCHEDULE = '/options/schedule',
  SCOPING_QUESTIONS_ADDRESS = '/book/[task_template_id]/scoping_questions/address',
  SCOPING_QUESTION = '/book/[task_template_id]/scoping_questions/[id]',
  SCOPING_QUESTIONS_SUMMARY = '/book/[task_template_id]/scoping_questions/summary',
}

export const bookingPaths = {
  confirm: (taskTemplateId: number | string) =>
    `/book/${taskTemplateId}/confirm`,
  details: (taskTemplateId: number | string) =>
    `/book/${taskTemplateId}/details`,
  match: (taskTemplateId: number | string) => `/book/${taskTemplateId}/match`,
  options: (taskTemplateId: number | string) =>
    `/book/${taskTemplateId}/options`,
  optionsSchedule: (taskTemplateId: number | string) =>
    `/book/${taskTemplateId}/options/schedule`,
  recommendations: (taskTemplateId: number | string) =>
    `/book/${taskTemplateId}/recommendations`,
  schedule: (taskTemplateId: number | string) =>
    `/book/${taskTemplateId}/schedule`,
};

export const scopingQuestionPaths = {
  address: (taskTemplateId: number | string) =>
    `/book/${taskTemplateId}/scoping_questions/address`,
  routingQuestion: (taskTemplateId: number | string) =>
    `/book/${taskTemplateId}/routing`,
  show: (
    taskTemplateId: number | string,
    scopingQuestionOrder: number | string,
    { editing }: { editing?: boolean } = {}
  ) =>
    `/book/${taskTemplateId}/scoping_questions/${scopingQuestionOrder}${
      editing ? '?editing=true' : ''
    }`,
  summary: (taskTemplateId: number | string) =>
    `/book/${taskTemplateId}/scoping_questions/summary`,
  vehicleQuestion: (taskTemplateId: number | string) =>
    `/book/${taskTemplateId}/scoping_questions/vehicle`,
};

export default paths;
