// External
import Box from '@taskrabbit/meadow-web/lib/Box';
import Container from '@taskrabbit/meadow-web/lib/Container';
import Image from 'next/image';
import styled from '@taskrabbit/meadow-web/lib/Theme/styled';
import Typography from '@taskrabbit/meadow-web/lib/Typography';

// Internal
import contentfulImageLoader from '~utils/contentfulImageLoader';
import { ReviewItem } from '~components/marketing/ReviewItem';

// Types
import type { HomeIndexData } from '~server/trpc/router/page/home/types';

const ReviewsContainer = styled('ul')(
  ({ theme: { breakpoints, spacing } }) => ({
    columnGap: spacing(6),
    display: 'flex',
    flexWrap: 'nowrap',
    marginBottom: spacing(6),
    overflowX: 'scroll',
    overflowY: 'hidden',
    paddingInlineStart: 0,
    width: '100%',

    [breakpoints.up('md')]: {
      columnGap: 0,
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      overflowX: 'hidden',
      rowGap: spacing(6),
    },
  })
);

const StyledImageWrapper = styled('div')(
  ({ theme: { breakpoints, spacing } }) => ({
    margin: '0 auto',
    maxWidth: '354px',
    minHeight: spacing(7),
    position: 'relative',

    [breakpoints.up('sm')]: {
      maxWidth: '612px',
      minHeight: spacing(11.25),
    },
  })
);

const Reviews = ({
  header,
  imageText,
  imageUrl,
  items,
  locale,
}: HomeIndexData['reviews']) => {
  return (
    <Box
      sx={({
        meadow: {
          purpose: { background },
        },
      }) => ({
        backgroundColor: background.emphasis,
      })}
    >
      <Container
        data-testid="reviews-container"
        sx={{
          pb: { md: 11, xs: 6 },
          pt: { md: 11, xs: 6 },
        }}
      >
        <Typography
          sx={({
            meadow: {
              purpose: { impact },
            },
          }) => ({
            color: impact.emphasis,
            mb: { md: 6, xs: 4 },
            textAlign: { md: 'center', xs: 'left' },
          })}
          variant="h2"
        >
          {header}
        </Typography>
        <ReviewsContainer>
          {items.map((item) => (
            <ReviewItem key={item.id} locale={locale} {...item} />
          ))}
        </ReviewsContainer>
        {imageUrl && (
          <StyledImageWrapper>
            <Image
              alt={imageText}
              fill={true}
              loader={contentfulImageLoader}
              priority={true}
              sizes="612px"
              src={imageUrl}
              style={{ objectFit: 'contain' }}
            />
          </StyledImageWrapper>
        )}
      </Container>
    </Box>
  );
};

export default Reviews;
