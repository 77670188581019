// External
import Container from '@taskrabbit/meadow-web/lib/Container';
import Grid from '@taskrabbit/meadow-web/lib/Grid';
import styled from '@taskrabbit/meadow-web/lib/Theme/styled';
import Typography from '@taskrabbit/meadow-web/lib/Typography';
import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';

// Internal
import useSegment from '~hooks/useSegment';
import { trpc } from '~utils/trpc';
import type {
  HomeIndexData
} from '~server/trpc/router/page/home/types';
import contentfulImageLoader from '~utils/contentfulImageLoader';
import {
  PAGE_HOME,
  POPULAR_PROJECTS_SECTION,
  RECOMMENDATION_CATEGORY_PICKED,
} from '~hooks/useSegment/eventNames';

// Type
type GridItemProps = {
  $isMobileHidden?: boolean;
};
// Styled Components
const PopularProjectsContainer = styled(Container)(
  ({ theme: { breakpoints, spacing } }) => ({
    paddingBottom: spacing(6),
    paddingTop: spacing(6),

    [breakpoints.up('md')]: {
      paddingBottom: spacing(11),
      paddingTop: spacing(11),
    },
  })
);

const Title = styled(Typography)(
  ({
    theme: {
      breakpoints,
      meadow: {
        purpose: { impact },
      },
      spacing,
    },
  }) => ({
    color: impact.main,
    marginBottom: spacing(2.5),

    [breakpoints.up('md')]: {
      marginBottom: spacing(3.75),
    },
  })
);

const GridContainer = styled(Grid)(({ theme: { spacing } }) => ({
  marginBottom: spacing(3),
}));

const GridItem = styled(Grid)<GridItemProps>(
  ({ $isMobileHidden, theme: { breakpoints } }) => ({
    display: $isMobileHidden ? 'none' : 'initial',

    [breakpoints.up('sm')]: {
      display: 'initial',
    },
  })
);

const ProjectWrapper = styled(Link)`
  display: block;
  text-decoration: none;
`;

const Project = styled('div')(
  ({
    theme: {
      breakpoints,
      spacing,
      meadow: {
        purpose: { background },
      },
    },
  }) => ({
    background: background.extraEmphasis,
    borderRadius: spacing(2),
    boxShadow:
      '0px 0px 4px 0px rgba(7, 7, 7, 0.10), 0px 0px 8px 0px rgba(4, 46, 41, 0.14)',
    cursor: 'pointer',
    display: 'flex',
    height: '88px',
    width: '100%',

    [breakpoints.up('sm')]: {
      flexDirection: 'column',
      height: '258px',
      width: '258px',
    },
  })
);

const ProjectImageContainer = styled('div')(({ theme: { breakpoints } }) => ({
  height: '88px',
  minWidth: '111px',
  position: 'relative',
  width: '111px',

  [breakpoints.up('sm')]: {
    flex: '163px 0 0',
    width: '258px',
  },
}));

const ProjectImage = styled(Image)`
  object-fit: cover;
`;

const ProjectData = styled('div')(({ theme: { breakpoints, spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(1),
  justifyContent: 'center',
  overflow: 'hidden',
  paddingLeft: spacing(2),
  paddingRight: spacing(2),

  [breakpoints.up('sm')]: {
    flex: '95px 1 1',
    padding: spacing(0, 2),
    textAlign: 'center',
  },
}));

const ProjectHeader = styled(Typography)(({ theme: { breakpoints } }) => ({
  fontSize: '14px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',

  [breakpoints.up('sm')]: {
    fontSize: '16px',
  },
}));

const ProjectSubheader = styled(Typography)(({ theme: { breakpoints } }) => ({
  fontSize: '12px',

  [breakpoints.up('sm')]: {
    fontSize: '14px',
  },
}));

const SeeMoreProjectsLabel = styled(Typography)(
  ({
    theme: {
      breakpoints,
      meadow: {
        purpose: { primary },
      },
    },
  }) => ({
    border: 'none',
    color: primary.main,
    width: '100%',

    [breakpoints.up('sm')]: {
      display: 'none',
    },
  })
);
const gridContainerConfig = {
  columnSpacing: { lg: 5, sm: 3, xs: 0 },
  container: true,
  rowSpacing: { lg: 7, sm: 4, xs: 3 },
};

const gridItemConfig = {
  item: true,
  lg: 3,
  md: 4,
  sm: 6,
  xs: 12,
};

const PopularProjects = ({
  linkCards,
  header,
  seeMoreText,
}: HomeIndexData['popularProjects']) => {
  const [allProjectsVisible, setAllProjectsVisible] = useState(false);
  const { trackEvent } = useSegment();
  const trpcContext = trpc.useContext();

  const projectClickHandler = async (url: string, header: string = '') => {
    const bootstrapData = await trpcContext.page.home.bootstrap.fetch();
    const metro = bootstrapData.bff?.metro;
    trackEvent({
      name: RECOMMENDATION_CATEGORY_PICKED,
      properties: {
        job_category: null,
        job_category_id: null,
        link_label: header,
        metro: metro?.name ?? null,
        metro_id: metro?.id ?? null,
        page: PAGE_HOME,
        task_template_id: url.match(/\d+/g)?.pop(),
        trigger_location: POPULAR_PROJECTS_SECTION,
      },
    });

    window.location.href = url;
  };

  return (
    <PopularProjectsContainer data-testid="popular-projects-section">
      <Title variant="h2">{header}</Title>
      <GridContainer {...gridContainerConfig}>
        {linkCards.map((project, index) => (
          <GridItem
            $isMobileHidden={!allProjectsVisible && index > 3}
            key={project.header}
            {...gridItemConfig}
          >
            <ProjectWrapper
              href={project.linkUrl}
              onClick={(e) => {
                e.preventDefault();
                projectClickHandler(project.linkUrl, project.header);
              }}
            >
              <Project>
                <ProjectImageContainer>
                  <ProjectImage
                    alt={project.imageText}
                    fill
                    loader={contentfulImageLoader}
                    sizes="384px"
                    src={project.imageUrl}
                  />
                </ProjectImageContainer>
                <ProjectData>
                  <ProjectHeader
                    sx={{ color: 'impact.main' }}
                    variant="subtitle4"
                  >
                    {project.header}
                  </ProjectHeader>
                  <ProjectSubheader
                    sx={{ color: 'impact.main' }}
                    variant="body3"
                  >
                    {project.subheader}
                  </ProjectSubheader>
                </ProjectData>
              </Project>
            </ProjectWrapper>
          </GridItem>
        ))}
      </GridContainer>
      {allProjectsVisible ? null : (
        <SeeMoreProjectsLabel
          onClick={() => setAllProjectsVisible(true)}
          variant="button1"
        >
          {seeMoreText}
        </SeeMoreProjectsLabel>
      )}
    </PopularProjectsContainer>
  );
};

export default PopularProjects;
